.app {
  background-image: url(bg2.svg);
  background-position-y: -10px;
  background-size: auto 280px;
  background-repeat: repeat-x;
  box-sizing: border-box;
  display: block;
  min-height: 900px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.green {
  color: #44772C!important
}

.red {
  color: red!important
}
.padding {
  margin-bottom: 5px;
}


.bold-row {
  font-weight: bold;
}

button.p-stepper-action {
  background: none;
}
.p-stepper-number {
  border: 1px solid  #44772C;
  color: #44772C
}

.navButtons {
  background-color: #44772C;
  border: none;
  margin: 5px;
}
.formHeader {
  width: 100%;
  height: 100px;
  display: flex;
}
.mainAppContainer {
  min-height: 700px;
}
#footerBanner {
  background-image: url(bg1.svg);
  margin-top: 60px;
  background-size: auto 75px;
  background-repeat: repeat-x;
  box-sizing: border-box;
}
.footerPanel {

  display: block;
  min-height: 250px;

}
.headerMenuPanel {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 25px;
  margin-left: 25px;
}




.headerTitle {
  height: 100%;
  width: 25%;
  margin-top: 25px;
  margin-left: 25px;
}
.headerPanel {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's on top of other elements */
  background-color: white; /* Add background color if needed */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.dropdown-label {
  font-weight: bold;
}